<template>
    <markets-ui-search-results
        :class="cls"
        :style="styles"
        ref="searchResults"
        :skin="watchedSettings.skin"
        :header-config="headerConfig"
        :data-model="dataList"
        :width="computedWidth"
        :loading="loading"
        :no-data-label="initedLabels.noData"
        :visible="visible"
        :focus-index="focusIndex"
        :size="watchedSettings.size"
        @click="onClick"
        @hide="hide"
    />
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
import labels from './assets/labels.json';
import { MAX_DISPLAY_LENGTH } from './metadata/securities';
const { utils, mixins } = mwcMarketsCore;
export default {
    name: 'mwc-markets-autocomplete-ui',
    mixins: [mixins.componentUI],
    props: {
        dataModel: {
            type: Array,
            default() {
                return [];
            }
        },
        elements: {
            type: Array,
            default() {
                return [];
            }
        },
        width: {
            type: Number
        },
        size: {
            type: String
        },
        classes: {
            type: Array,
            default() {
                return [];
            }
        },
        securities: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            loading: false,
            dataList: [],
            visible: false,
            focusIndex: -1,
            isFluid: false,
            styles: {},
            containerRect: {}
        };
    },
    watch: {
        loading(value) {
            if (value) {
                this.visible = true;
                this.update();
            }
        },
        visible(value) {
            if (!value) {
                this.dataList = [];

                this.loading = false;
                this.$emit('show-template', this.visible);
            }
        },
        size(value) {
            this.watchedSettings.size = value;
        },
        width(value) {
            this.watchedSettings.width = value;
        },
        securities: {
            handler(value) {
                this.watchedSettings.securities = value;
            },
            immediate: true,
            deep: true
        },
        elements: {
            handler(value, oldValue) {
                if (oldValue.length) {
                    this.unBindEvents(oldValue);
                }
                if (value.length) {
                    this.bindEvents(value);
                }
            },
            deep: true
        },
        dataModel: {
            handler(value) {
                if (!this.currentInputTarget || !this.visible) {
                    return;
                }
                const keyword = this.currentInputTarget.value;
                const securityMap = {};
                value.forEach(item => {
                    const type =
                        utils.Security_Types[item.mstarType] ||
                        utils.Security_Types[item.rtType];
                    const securityType =
                        utils.Autocomplete_Sec_Types[type] || 'others';
                    if (!securityMap[securityType]) {
                        securityMap[securityType] = [];
                    }
                    let name = item.name || '';
                    let symbol =
                        item.clientSymbol ||
                        item.mstarSymbol ||
                        item.rtSymbol ||
                        '';
                    if (securityType === 'option') {
                        name = item.clientSymbol;
                        symbol = item.rtSymbol;
                    }
                    const id = this._getIdentifier(item);
                    const symbolHTML = this.highlight(symbol, keyword);
                    const exchange = item.shortExchangeName;

                    const symbolAndExchange = `<span class="${this.namespace(
                        'cell--symbol'
                    )}">${symbolHTML}</span><span class="${this.namespace(
                        'cell--exchange'
                    )} ${
                        symbol ? this.namespace('cell--exchange-dot') : ''
                    }">${exchange}</span>`;
                    securityMap[securityType].push({
                        id,
                        identifier: id,
                        name,
                        nameHTML: this.highlight(name, keyword),
                        exchange,
                        performanceId: item.performanceId,
                        securityId: item.securityId,
                        securityType: item.mstarType,
                        symbol,
                        currency: item.currency,
                        symbolHTML,
                        symbolAndExchange
                    });
                });
                const dataList = [];
                const securities = this.getSupportedSecurities();
                securities.forEach(c => {
                    if (securityMap[c]) {
                        const group = utils.find(
                            dataList,
                            item => item.id === c
                        );
                        const maxLen =
                            MAX_DISPLAY_LENGTH[c] || MAX_DISPLAY_LENGTH.other;
                        const groupItems = securityMap[c].slice(0, maxLen);
                        groupItems[groupItems.length - 1].isLast = true;
                        if (!group) {
                            dataList.push({
                                id: c,
                                groupName: this.initedLabels[c],
                                list: groupItems
                            });
                        }
                    }
                });
                this.dataList = dataList;
                this.visible = true;
                this.loading = false;
                this.update();
            },
            deep: true
        }
    },
    mounted() {
        document.body.appendChild(this.$el);
        document.addEventListener('click', this.validateClick);
        utils.resizeObserver.observe(
            document.documentElement,
            this._resizeCallback
        );
        this.containerRect = {
            height: document.documentElement.clientHeight,
            width: document.documentElement.clientWidth
        };
    },
    beforeDestroy() {
        this.unBindEvents(this.elements);
        document.removeEventListener('click', this.validateClick);
        utils.resizeObserver.unobserve(
            document.documentElement,
            this._resizeCallback
        );
    },
    created() {
        this.initedLabels = this.mergeLabels(
            this.labels,
            this.getDefaultLabels(labels, this.watchedSettings.languageId)
        );
        this.namespace = utils.namespace('autocomplete');
        if (this.width) {
            this.watchedSettings.width = this.width;
        }
        if (this.size) {
            this.watchedSettings.size = this.size;
        }
        this.currentInputTarget = null;
        this.inputRect = {};
        this.lastSecurityMap = {};
    },
    computed: {
        cls() {
            const cls = [this.namespace(), ...this.classes];
            if (this.watchedSettings.skin) {
                cls.push(
                    this.namespace(
                        `-${utils.getSkinSuffix(this.watchedSettings.skin)}`
                    )
                );
            }
            if (this.visible) {
                cls.push(this.namespace('visible'));
            }
            return cls;
        },
        // return the autocomplete popover size
        computedWidth() {
            return this.isFluid
                ? 'fluid'
                : this.watchedSettings.width || this.containerRect.width;
        },
        headerConfig() {
            return [
                {
                    fieldName: 'nameHTML',
                    text: 'Name',
                    width: '64%'
                },
                {
                    fieldName: 'symbolAndExchange',
                    text: 'Symbol',
                    align: 'right',
                    class: this.namespace('symbol-exchange'),
                    width: '36%'
                }
            ];
        }
    },
    methods: {
        validateClick(e) {
            const elem = e.target || e.srcElement;
            if (this.currentInputTarget) {
                if (
                    this.currentInputTarget === elem &&
                    this.currentInputTarget.value !== '' &&
                    this.dataModel.length > 0
                ) {
                    this.visible = true;
                } else if (
                    !document
                        .querySelector(`.${this.namespace()}`)
                        .contains(elem)
                ) {
                    this.hide();
                }
            }
        },
        bindEvents(elements) {
            elements.forEach(element => {
                const ele = document.getElementById(element.id);
                if (ele) {
                    ele.addEventListener('input', this.changeValue, false);
                    // why use keydown instead of keyup: to avoid case like: An enter event triggered => search input focused => search input enter event will triggered incorrectly
                    ele.addEventListener('keydown', this.onInputKeydown);
                    ele.addEventListener('focus', this.onInputFocus);
                }
            });
        },
        unBindEvents(elements) {
            elements.forEach(element => {
                const ele = document.getElementById(element.id);
                if (ele) {
                    ele.removeEventListener('input', this.changeValue);
                }
            });
        },
        onInputFocus(e) {
            this.currentInputTarget = e.target;
            this.focusIndex = -1;
            if (this.watchedSettings.focusSelect) {
                e.target.select();
            } else if (this.watchedSettings.focusClear) {
                e.target.value = '';
            }
        },
        onInputKeydown(e) {
            if (e.keyCode === 40 && !this.visible) {
                const event = document.createEvent('Event');
                event.initEvent('input', true, false);
                e.target.dispatchEvent(event);
            } else if (e.keyCode === 40 && this.visible) {
                this.focusIndex = 0;
            } else if (
                e.keyCode === 13 &&
                this.watchedSettings.enableEnter &&
                this.currentInputTarget
            ) {
                const last = this.lastSecurityMap[this.currentInputTarget.id];
                const element = this._findCurrentElement();
                this.hide();
                if (last && last.searchKey === this.currentInputTarget.value) {
                    element.handler(last.security);
                } else if (this.currentInputTarget.value) {
                    this.$emit('security-search', {
                        id: this.currentInputTarget.id,
                        key: this.currentInputTarget.value
                    });
                }
            }
        },
        searchSecuritySuccess(id, security) {
            const element = this._findCurrentElement(id);
            const _security = this._getSecurityInfo(security);
            element.handler(_security);
            this.lastSecurityMap[id] = _security;
        },
        changeValue(e) {
            const target = e.target;
            if (target.value !== '') {
                this.currentInputTarget = target;
                if (!target.id) {
                    this.currentInputTarget.id = e.currentTarget.id;
                }
                this.loading = true;
                this.$emit('search', {
                    kw: target.value,
                    condition: this.generateCondition()
                });
            } else {
                this.currentInputTarget = null;
                this.hide();
            }
        },
        hide() {
            if (this.visible) {
                this.visible = false;
                this.focusIndex = -1;
                if (this.currentInputTarget) {
                    this.currentInputTarget.focus();
                }
            }
        },
        getSupportedSecurities() {
            let securities = [];
            const element = this._findCurrentElement();
            if (element && element.cfg) {
                securities = element.cfg.securities || [];
            }
            if (securities.length === 0) {
                securities = this.watchedSettings.securities;
            }
            return securities;
        },
        generateCondition() {
            const condition = [];
            const securities = this.getSupportedSecurities();
            securities.forEach(securityType => {
                condition.push(...this._getSecurityTypes(securityType));
            });
            return condition.join(',');
        },
        update() {
            if (!this.currentInputTarget) {
                return;
            }
            this.containerRect = {
                height: document.documentElement.clientHeight,
                width: document.documentElement.clientWidth
            };
            const inputTop = document.documentElement.scrollTop
                ? document.documentElement.scrollTop +
                  this.currentInputTarget.getBoundingClientRect().top
                : this.currentInputTarget.getBoundingClientRect().top;

            const inputLeft = document.documentElement.scrollLeft
                ? document.documentElement.scrollLeft +
                  this.currentInputTarget.getBoundingClientRect().left
                : this.currentInputTarget.getBoundingClientRect().left;

            this.inputRect = {
                width: this.currentInputTarget.offsetWidth,
                height: this.currentInputTarget.offsetHeight,
                top: inputTop,
                left: inputLeft
            };

            this.$set(this.styles, 'height', 'auto');

            // if the width is setting and width more than container width
            if (
                this.watchedSettings.width &&
                this.watchedSettings.width > 300 &&
                this.containerRect.width > this.watchedSettings.width
            ) {
                this.$set(
                    this.styles,
                    'width',
                    `${this.watchedSettings.width}px`
                );
                this.isFluid = true;
            } else {
                this.$delete(this.styles, 'width');
                this.isFluid = false;
            }

            this.$nextTick(() => {
                this.adjustPosition();
            });
        },
        adjustPositionTop({ inputBottom, resultsRect }) {
            let searchHeight, searchTop;
            // calculate height and top
            if (inputBottom >= resultsRect.height) {
                // if the bottom empty area height more than self height
                searchHeight = resultsRect.height;
                searchTop = this.inputRect.top + this.inputRect.height;
            } else {
                if (
                    inputBottom >= this.watchedSettings.bottomMargin ||
                    inputBottom > this.inputRect.top
                ) {
                    // if the bottom empty area height more than bottom margin or bottom empty area more than top area,
                    // then autocomplete is will display on bottom with scrollbar
                    searchHeight = inputBottom;
                    searchTop = this.inputRect.top + this.inputRect.height;
                } else {
                    // if the bottom empty area height less than bottom margin and self height, then autocomplete will display on top.
                    searchHeight = Math.min(
                        resultsRect.height,
                        this.inputRect.top
                    );
                    searchTop = Math.max(
                        0,
                        this.inputRect.top - resultsRect.height - 10
                    );
                }
            }
            return { searchHeight, searchTop };
        },
        adjustPositionLeft({ inputRight, resultsRect }) {
            let searchLeft;
            // calculate left
            if (resultsRect.width === this.containerRect.width) {
                searchLeft = 0;
            } else {
                if (inputRight >= resultsRect.width) {
                    searchLeft = this.inputRect.left;
                } else {
                    searchLeft =
                        this.inputRect.left +
                        this.inputRect.width -
                        resultsRect.width;
                    // set left position to 0 when it not in view window
                    if (searchLeft < 0) {
                        //if the left area is on engouth, set it begin from 0
                        searchLeft = 0;
                    }
                }
            }
            return { searchLeft };
        },
        adjustPosition() {
            const updateFunc = () => {
                const resultsRect = utils.outerSizes(
                    this.$refs.searchResults.$el,
                    true
                );
                if (!this.elMargins) {
                    this.elMargins = utils.getMargins(this.$el);
                }

                const inputRight =
                    this.containerRect.width - this.inputRect.left;

                let inputBottom =
                    this.containerRect.height -
                    this.inputRect.top -
                    this.inputRect.height;

                inputBottom = document.documentElement.scrollTop
                    ? inputBottom + document.documentElement.scrollTop
                    : inputBottom;
                const { searchHeight, searchTop } = this.adjustPositionTop({
                    inputBottom,
                    resultsRect
                });
                const { searchLeft } = this.adjustPositionLeft({
                    inputRight,
                    resultsRect
                });

                const height =
                    searchHeight - this.elMargins.top - this.elMargins.bottom;
                // the max-height in small screen is 390
                // the max-height in medium and alarge screen is 540
                if (
                    height < 390 ||
                    (height < 540 && this.watchedSettings.size !== 'small')
                ) {
                    this.$set(this.styles, 'height', `${height}px`);
                }

                this.$set(this.styles, 'top', `${searchTop}px`);
                this.$set(this.styles, 'left', `${searchLeft}px`);
            };
            utils.debounce(updateFunc, 10)();
        },
        highlight(str, key) {
            const pattn = new RegExp(key, 'i');
            const tmatch = str.match(pattn);
            if (tmatch !== null) {
                return str.replace(
                    pattn,
                    `<span class="${this.moduleClassName(
                        'mds-search-results--text-match'
                    )}">${tmatch[0]}</span>`
                );
            }
            return str;
        },

        onClick(item, e) {
            const element = this._findCurrentElement();
            if (item && element) {
                const security = this._getSecurityInfo(item);
                this.lastSecurityMap[this.currentInputTarget.id] = {
                    searchKey: this.currentInputTarget.value,
                    security
                };
                element.handler(security);
            }
            this.hide();
        },
        _getSecurityTypes(category) {
            const ret = [];
            for (const type in utils.Autocomplete_Sec_Types) {
                if (utils.Autocomplete_Sec_Types[type] === category) {
                    ret.push(type);
                }
            }
            return ret;
        },
        _findCurrentElement(id) {
            if (!id) {
                id = this.currentInputTarget.id;
            }
            return utils.find(this.elements, ele => ele.id === id);
        },
        _getIdentifier(security) {
            return (
                security.identifier ||
                security.quoteInstrument ||
                security.instrument ||
                security.securityId ||
                security.performanceId ||
                ''
            );
        },
        _getSecurityInfo(data) {
            return {
                identifier: this._getIdentifier(data),
                performanceId: data.performanceId,
                securityId: data.securityId,
                name: data.name,
                symbol: data.symbol,
                currency: data.currency,
                securityType: data.securityType,
                exchange: data.exchange
            };
        },
        _resizeCallback({ entry }) {
            const { width, height } = entry.contentRect;
            this.containerRect = {
                height,
                width
            };
            this.update();
        }
    }
};
</script>

<style lang="scss">
@import '@mds/constants';
@import '@mds/typography';
$namespace: 'mwc-markets-autocomplete';

.#{$namespace} {
    @include mds-body-text-s();
    color: $mds-text-color-primary;
    position: absolute;
    margin: $mds-space-1-x 0;
    display: none;
    &:focus {
        outline: none;
    }
    &-visible {
        display: block;
    }
    &-row--active {
        background-color: $mds-background-color-light-gray;
    }
    &-cell--exchange {
        &-dot:before {
            content: '';
            margin: 0 $mds-space-half-x;
            border-radius: 2px;
            width: 4px;
            height: 4px;
            display: inline-block;
            vertical-align: middle;
            border: 1px solid $mds-color-neutral-20;
            background-color: $mds-color-neutral-20;
        }
    }
    &.mds-search-results--width-300px___markets {
        .#{$namespace}-cell--exchange:before {
            display: none;
        }
        .#{$namespace}-symbol-exchange {
            .#{$namespace}-cell--symbol,
            .#{$namespace}-cell--exchange {
                display: block;
            }
        }
    }

    &.#{$namespace}--dark-gray {
        color: $mds-text-color-primary-on-dark;
        .mds-section--primary___markets {
            border-top-color: $mds-color-neutral-90;
        }
        .#{$namespace}-link--no-underline:visited,
        .mds-section__title___markets {
            color: $mds-text-color-primary-on-dark;
        }
        .#{$namespace}-cell--exchange:before {
            background-color: $mds-text-color-primary-on-dark;
            border-color: $mds-text-color-primary-on-dark;
        }
        .#{$namespace}-data-table__row--active {
            background: $mds-color-neutral-20;
        }
        .#{$namespace}-row--active {
            background: $mds-color-neutral-20;
        }
        &.mds-search-results___markets
            .mds-search-results--text-match___markets {
            background-color: $mds-interactive-color-primary-active;
        }
    }
}
</style>
