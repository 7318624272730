var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AUTOCOMPLETEUI", {
    ref: "UI",
    attrs: {
      classes: _vm.classes,
      skin: _vm.skin,
      width: _vm.width,
      dataModel: _vm.dataModel,
      elements: _vm.elements,
      settings: _vm.settings,
      labels: _vm.initedLabels,
      formatter: _vm.formatter,
      size: _vm.size,
      securities: _vm.securities
    },
    on: {
      "show-template": _vm.showTemplate,
      search: _vm.throttleSearch,
      "security-search": _vm.getSecurityInfo
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }