export const STOCKS_TYPES = {
    ST01: 'commonStock',
    ST02: 'preferredStock',
    STADR: 'ADR'
};

export const MAX_DISPLAY_LENGTH = {
    stock: 12,
    index: 5,
    other: 5
};
