var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("markets-ui-search-results", {
    ref: "searchResults",
    class: _vm.cls,
    style: _vm.styles,
    attrs: {
      skin: _vm.watchedSettings.skin,
      "header-config": _vm.headerConfig,
      "data-model": _vm.dataList,
      width: _vm.computedWidth,
      loading: _vm.loading,
      "no-data-label": _vm.initedLabels.noData,
      visible: _vm.visible,
      "focus-index": _vm.focusIndex,
      size: _vm.watchedSettings.size
    },
    on: { click: _vm.onClick, hide: _vm.hide }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }