<template>
    <AUTOCOMPLETEUI
        ref="UI"
        :classes="classes"
        :skin="skin"
        :width="width"
        :dataModel="dataModel"
        :elements="elements"
        :settings="settings"
        :labels="initedLabels"
        :formatter="formatter"
        :size="size"
        :securities="securities"
        @show-template="showTemplate"
        @search="throttleSearch"
        @security-search="getSecurityInfo"
    >
    </AUTOCOMPLETEUI>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
import labels from './assets/labels.json';
import AUTOCOMPLETEUI from './autocomplete-ui';
const { utils, mixins } = mwcMarketsCore;
const COMPONENT_NAME = 'mwc-markets-autocomplete';
export default {
    name: COMPONENT_NAME,
    mixins: [mixins.MwcVueHelperMixin, mixins.component],
    props: {
        width: Number,
        securities: {
            type: Array,
            default() {
                return [];
            }
        },
        size: {
            type: String,
            default: 'medium',
            validator(value) {
                return utils.inArray(['medium', 'small'], value) > -1;
            }
        },
        classes: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    created() {
        this.currentReqeustParams = null;
        this.throttleSearch = utils.throttle(params => {
            if (this.mktdata) {
                this.currentReqeustParams = utils.extend(
                    {
                        id: utils.guid()
                    },
                    params
                );
                this.mktdata
                    .autocomplete(this.currentReqeustParams)
                    .then(data => {
                        if (this.currentReqeustParams.id === data.id) {
                            this.dataModel = data.result;
                            this.trackEvent({
                                name: 'search',
                                value: params.kw
                            });
                        }
                    })
                    .catch(error => {
                        this.dataModel = [];
                    });
            } else {
                this.dataModel = [];
            }
        }, 500);
    },
    data() {
        return {
            defaultConfig: {
                settings: {
                    width: null,
                    size: 'medium',
                    bottomMargin: 300,
                    enableEnter: true,
                    focusSelect: true, // the text would be selected on focus
                    focusClear: false, // the text would be clear on focus
                    securities: [
                        'stock',
                        'ETF',
                        'CEF',
                        'index',
                        'mutualFund',
                        'VA',
                        'VL',
                        'FH',
                        'SA',
                        'CZ',
                        'FI',
                        'FV',
                        'PS',
                        'CT',
                        'V1',
                        'VP',
                        'LP',
                        'MO',
                        'R1',
                        'SP',
                        'CA'
                    ]
                },
                labels,
                intlNamespace: COMPONENT_NAME
            },
            dataModel: [],
            elements: []
        };
    },
    computed: {
        initedLabels() {
            return this.mergeLabels(labels);
        }
    },
    methods: {
        exposeMethods() {
            return ['register', 'unregister', 'hide'];
        },
        hide() {
            this.$refs.UI.hide();
        },
        register(id, handler, cfg) {
            if (utils.isFunction(handler)) {
                const elements = this.elements.slice();
                let idx = utils.findIndex(elements, ele => ele.id === id);
                if (idx < 0) {
                    idx = elements.length;
                }
                const element = {
                    id,
                    handler,
                    cfg
                };
                this.$set(this.elements, idx, element);
            }
        },
        unregister(id) {
            const elements = this.elements.slice();
            const idx = utils.findIndex(elements, ele => ele.id === id);
            if (idx > -1) {
                elements.splice(idx, 1);
                this.elements = elements;
            }
        },
        showTemplate(e) {
            if (!e) {
                this.dataModel = [];
            }
        },
        getSecurityInfo({ key, id }) {
            this.mktdata
                .securities([key])
                .then(securities => {
                    this.$refs.UI.searchSecuritySuccess(id, securities[0]);
                })
                .catch(e => {
                    this.$refs.UI.searchSecuritySuccess(id, {
                        invalid: false,
                        queryKey: key
                    });
                });
        }
    },
    components: {
        AUTOCOMPLETEUI
    }
};
</script>
